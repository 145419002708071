import React from 'react';
import './Locations.css';
import Portrait_Montage from '../pics/Portrait_Montage.jpg';

function Locations() {
  return (
    <div className="max-w-2xl mx-auto p-8">
      <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg overflow-auto text-center">
        <header>
          <h1>Senior & Graduation Portraits</h1>
          <p>Capture your best moments with us!</p>
        </header>
        <h1 className="text-black text-2xl font-bold mb-6">Portrait Locations</h1>
        <img src={Portrait_Montage} alt="Portrait Montage" width="700" />
        <table className="table-auto w-full mt-6">
          <tbody>
            <tr><td>Indiana University</td><td>Franklin Hall</td></tr>
            <tr><td>Cedarville University</td><td>Biblical & Theological Studies Building</td></tr>
            <tr><td>Olivet Nazarene University</td><td>Perry Center Fieldhouse</td></tr>
            <tr><td>Saint Mary's College</td><td>Student Center</td></tr>
            <tr><td>Bowling Green State University</td><td>Bowen Thompson Student Union Room 208</td></tr>
            <tr><td>Ohio University</td><td>Baker University Center Room 321</td></tr>
            <tr><td>Ohio Northern University</td><td>McIntosh Lounge</td></tr>
            <tr><td>Valparaiso University</td><td>Victory Bell Room #263 Harre Student Union</td></tr>
            <tr><td>Bethel College</td><td>The Lodge (Conference Room)</td></tr>
            <tr><td>Butler University</td><td>Atherton Union Room 302 (Stegman Conference Room)</td></tr>
            <tr><td>Taylor University</td><td>Rupp Communication Arts Building - Upper Theatre Lobby</td></tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Locations;