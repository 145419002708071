import React from 'react';
import './Wear.css';
import Portrait_Montage from '../pics/Portrait_Montage.jpg';

function Wear() {
  return (
     <div className="max-w-2xl mx-auto p-8">
      <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg overflow-auto text-center">
        <header>
          <h1>Senior & Graduation Portraits</h1>
          <p>Capture your best moments with us!</p>
        </header>
        <h1 className="text-black text-2xl font-bold mb-6">
          What Should I Wear?
        </h1>
        <img src={Portrait_Montage} alt="Portrait Montage" width="700" />
        
        <div className="text-black space-y-6">
          <div>
            <h2 className="font-bold mb-2 text-left">Men:</h2>
            <p className="leading-relaxed break-words">
              Shirt and tie should be worn. 
              (Sports or suit jackets are optional, 
              but men should shave prior to being photographed 
              as beard stubble cannot be retouched.)
            </p>
          </div>
          
          <div>
            <h2 className="font-bold mb-2 text-left">Women:</h2>
            <p className="leading-relaxed break-words">
              Dressy top or blouse should be worn. 
              (Turtleneck and cowl-neck sweaters do not work well 
              with the cap and gown portraits. A mirror is available 
              for checking hair and makeup prior to portraits being taken.)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wear;