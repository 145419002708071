import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="max-w-7xl mx-auto px-4 text-right text-black-600">
        <p>© {currentYear} MJM Photo. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;