import React from 'react';
import './Home.css';
import Portrait_Montage from '../pics/Portrait_Montage.jpg';

function Home() {
return (
    <div className="max-w-2xl mx-auto p-8">
        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg overflow-auto text-center">
            <header>
                <h1>Senior & Graduation Portraits</h1>
                <p>Capture your best moments with us!</p>
            </header>
            <h1 className="text-black text-2xl font-bold mb-6">
                Make Your Portrait Appointment Today!
            </h1>
            <img src={Portrait_Montage} alt="Portrait Montage" width="700" />
            <h2 className="text-black text-xl font-bold mb-4">Look Your Best!</h2>
            <a href="https://app.timetrade.com/tc/login.do?url=mjmphotography" className="block text-center underline">
                Click to schedule your senior portrait appointment!
            </a>
        </div>
    </div>
);
}

export default Home;