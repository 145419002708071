import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Wear from './pages/Wear';
import Locations from './pages/Locations';
import Footer from './components/footer';
// import { AnimatedBackground } from 'animated-backgrounds';
import Navigation from './components/Navigation';
// import './App.css';

function App() {
  return (
    <>
      <Router>
        <Navigation />
        <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <div style={{ flex: '1' }}>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/wear" element={<Wear />} />
              <Route path="/locations" element={<Locations />} />
              <Route path="*" element={<Home />} /> {/* Catch-all route */}
            </Routes>
          </div>
        </div>
      </Router>
      {/* <AnimatedBackground animationName="gradientWave" /> */}
      <Footer />
    </>
  );
}

export default App;